<template>
  <div id="home">
    <div class="top__section">
      <div class="top__section__container">
        <StarDonations />
      </div>
    </div>
    <div class="bottom__section">
      <div class="bottom__section__container">
        <Donations />
        <ShareButtons />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "landing",
  components: {
    StarDonations: () => import("@/components/star-donations"),
    Donations: () => import("@/components/donations"),
    ShareButtons: () => import("@/components/share-buttons"),
    Footer: () => import("@/components/footer")
  },
  computed: {
    ...mapGetters({
      getMainConfig: "partner/getMainConfig",
      getAuxValue: "partner/getAuxValue",
    })
  },
  methods: {
    ...mapActions({
      setPartner: "partner/setPartner"
    })
  },
  async created() {
    await this.setPartner([this.$route.path, this.getAuxValue]).then(() => {
      if (!this.getMainConfig.partner.id) {
        this.$router.push("/error");
      }
    });
  }
};
</script>

<style scoped lang="scss">
#home {
  overflow-x: hidden;
  text-align: center;
  width: 100%;

  @media (min-width: $breakpoint-xxl) {
    max-width: 1600px;
    margin: 0 auto;
  }

  .top__section {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .bottom__section {
    position: relative;
    display: flex;
    flex-direction: column;
    &__container {
      background-color: $color-grey-wild-sand;
    }
  }
  img {
    height: 100%;
  }
}
</style>
